<template>
    <div :class='[$store.state.layout_style, $store.state.menu_style]'>
        <component v-bind:is='layout'></component>
    </div>
</template>

<script setup>
    import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
    import { useStore } from 'vuex';
    import '@/assets/sass/app.scss';
    import { useMeta } from '@/composables/use-meta';
    import { showMessage } from '@/composables/utils';

    useMeta({ title: 'IT Folder' });

    const store = useStore();
    const layout = computed(() => store.getters.layout);
    const loggedIn = computed(() => store.state.auth.status.loggedIn);

    // Get idle time from the store or set default (e.g., 30 minutes)
    const idleTime = ref(store.state.auth?.token?.idle_minutes * 60000 || 1800000); // Convert minutes to milliseconds

    let idleTimer = null; // Main idle timer
    let forceLogoutTimer = null; // Timer to force logout if user doesn't respond

    // Function to start the idle timer
    const startIdleTimer = () => {
        clearTimeout(idleTimer);
        idleTimer = setTimeout(handleIdle, idleTime.value);
    };

    // Function to stop the idle timer
    const stopIdleTimer = () => {
        clearTimeout(idleTimer);
        clearTimeout(forceLogoutTimer);
    };

    // Function to handle idle event
    const handleIdle = () => {
        if (loggedIn.value) {
            // Show the alert
            Swal.fire({
                title: 'Stay Logged on?',
                text: 'You have been inactive for a while. Do you want to stay logged in?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                clearTimeout(forceLogoutTimer);

                if (result.isConfirmed) {
                    startIdleTimer();
                } else {
                    logout();
                }
            });

            // Start the force logout timer (e.g., 1 minute)
            forceLogoutTimer = setTimeout(() => {
                Swal.close(); // Close the alert if still open
                logout(); // Force logout if no response within the timeout
            }, 60000); // 1 minute in milliseconds
        }
    };

    // Function to log the user out
    const logout = () => {
        showMessage('You have been logged out due to inactivity', 'info');
        store.dispatch('auth/logout');
        location.href = '/login';
    };

    // Function to reset the idle timer
    const resetIdleTimer = () => {
        if (loggedIn.value) {
            startIdleTimer();
        }
    };

    // Watch the login state and idle time value
    watch([loggedIn, idleTime], ([isLoggedIn, currentIdleTime]) => {
        if (isLoggedIn) {
            startIdleTimer();
        } else {
            stopIdleTimer();
        }
    });

    // Watch for changes in the idle time value from the store
    watch(
        () => store.state.auth?.token?.idle_minutes,
        (newIdleTime) => {
            // Update idle time and convert to milliseconds
            idleTime.value = (newIdleTime || 30) * 60000;
            if (loggedIn.value) {
                startIdleTimer();
            }
        }
    );

    onMounted(() => {
        if (loggedIn.value) {
            startIdleTimer();
            document.addEventListener('mousemove', resetIdleTimer);
            document.addEventListener('mousedown', resetIdleTimer);
            document.addEventListener('keypress', resetIdleTimer);
            document.addEventListener('touchstart', resetIdleTimer);
            document.addEventListener('scroll', resetIdleTimer);
        }
    });

    onUnmounted(() => {
        stopIdleTimer();
        document.removeEventListener('mousemove', resetIdleTimer);
        document.removeEventListener('mousedown', resetIdleTimer);
        document.removeEventListener('keypress', resetIdleTimer);
        document.removeEventListener('touchstart', resetIdleTimer);
        document.removeEventListener('scroll', resetIdleTimer);
    });
</script>

<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import publicLayout from './layouts/public-layout.vue';

    export default {
        components: {
            app: appLayout,
            auth: authLayout,
            public: publicLayout
        }
    };
</script>
