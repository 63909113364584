import api from './api';
import TokenService from './token.service';

class AuthService {

    async get_user({ access_token }) {
        const headers = {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        };

        // Await the second API call
        const userResponse = await api.post('/auth/user', {}, { headers });

        return userResponse.data;
    }

    async login({ username, password }) {

        const loginFormData = new FormData();
        loginFormData.append('username', username);
        loginFormData.append('password', password);

        try {
            const response = await api.post('/auth/token', loginFormData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.access_token) {

                const userResponse = await this.get_user({ access_token: response.data.access_token });
                    if (userResponse.user && !userResponse.user.is_mfa_enabled) {
                    response.data.user = userResponse.user;
                    TokenService.setToken(response.data);
                } else {
                    response.data.user = { is_mfa_enabled: true };
                }

            }

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async sso_login({ access_token }) {
        return api
            .post('/auth/sso_token', {
                access_token: access_token
            })
            .then(async (response) => {

                const userResponse = await this.get_user({ access_token: response.data.access_token });
                if (response.data.access_token) {
                    response.data.user = userResponse.user;
                    TokenService.setToken(response.data);
                }
                return response.data;
            });
    }

    logout() {
        TokenService.removeToken();
    }

    register({ first_name, last_name, email, plan, password, company_name, has_accepted_terms }) {
        return api.post('/auth/register', {
            first_name,
            last_name,
            email,
            plan,
            company_name,
            password,
            has_accepted_terms
        });
    }

    confirmation({ token }) {
        return api.post('/auth/confirmation', {
            token
        });
    }

    new_user_invitation({ payload }) {
        return api.post('/auth/new_user_invitation', payload);
    }

    invitation_token_check({ token }) {
        return api.post('/auth/invitation_token_check', {
            token
        });
    }

    password_recovery({ email }) {
        return api.post('/auth/password_recovery', {
            email
        });
    }

    password_recovery_token_check({ token }) {
        return api.post('/auth/password_recovery_token_check', {
            token
        });
    }

    password_reset({ payload }) {
        return api.post('/auth/password_reset', payload);
    }

    change_password({ payload }) {
        return api.put('/auth/change_password', payload);
    }

    profile({ payload }) {
        return api.put('/auth/profile', payload);
    }
}

export default new AuthService();
